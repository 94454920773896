// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./custom/menu"
import "trix"
import "@rails/actiontext"


document.addEventListener("trix-file-accept", (e) => {
  e.preventDefault()
})
import "./controllers"
