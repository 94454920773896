const setState = (state) => (menu) => {
  if (state === "opened") {
    menu.classList.remove("hidden");
  }
  if (state === "closed") {
    menu.classList.add("hidden");
  }
};

const open = setState("opened");
const close = setState("closed");

document.addEventListener('DOMContentLoaded', startMobileMenu)
document.addEventListener('turbo:render', startMobileMenu)

function startMobileMenu() {
  const menu = document.getElementById("mobile-menu");
  const openBtn = document.getElementById("mobile-open-btn");
  const closeBtn = document.getElementById("mobile-close-btn");

  if (openBtn) {
    openBtn.addEventListener("click", () => open(menu));
  }

  if (closeBtn) {
    closeBtn.addEventListener("click", () => close(menu));
  }
};
